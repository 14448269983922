import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <p>
                  AmpedUp Group is a dynamic and innovative business consultancy
                  and brand development firm, dedicated to powering up
                  businesses across the Middle East and Africa. We specialize in
                  transforming companies, amplifying their market presence, and
                  accelerating their growth through strategic business
                  consulting and development, data-driven marketing solutions,
                  and event management integrated with data acquisition.
                </p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
