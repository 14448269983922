import React from "react";

export const CompanyOverView = () => {
  return (
    <div className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2">
          <h2>Our Vision</h2>
          <p>
            To be the leading catalyst for business transformation, market
            entry, brand elevation, fostering sustainable growth and innovation
            for our partners across the region.
          </p>
          <h2>Our Mission</h2>
          <p>
            At AmpedUp Group, our mission is to empower businesses with the
            tools, insights, and strategies they need to thrive in today’s
            fast-evolving market. We help our clients identify new opportunities
            for successful market entry, ensuring strong returns on investment.
          </p>
        </div>
      </div>
    </div>
  );
};
