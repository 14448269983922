import React from "react";

export const Commitment = () => {
  return (
    <div className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Our Commitment</h2>
          <p>
            We are committed to empowering our clients with the resources and
            strategies they need to lead in their industries, enhance their
            market position, and achieve sustainable growth. At AmpedUp Group,
            we don’t just consult; we collaborate and partner with businesses to
            ensure their long-term success.
          </p>
        </div>
      </div>
    </div>
  );
};
